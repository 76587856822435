import React from "react"

import { ModalLayout } from "../layouts/modal"

import { SignupForm } from "../forms/signup" // '../../shared/components/forms/login'

const Page = () => {

    const content = {}
    content.htmlTitle = 'Signup'
    content.pageTitle = 'Create your free account'
    content.pageTagline = 'Time for some data work'

    const form = <SignupForm />;

    return (
        <ModalLayout {...content} form={form} />
    )

}

export default Page