import React from "react";
import * as Yup from "yup";
import { Link } from 'gatsby'

import { ApolloProvider } from "@apollo/client"
import { client } from "../../app/provider/apollo/client"

import { QUERY } from "../../queries/signup"
import { FormWrapper } from "../wrapper"

const SignupForm = (props) => {

    const schema = {
        email: Yup.string().email("Invalid Email Address").required("*Required"),
        password: Yup.string().required("*Required"),
    };

    const inputFields = {
        email: {
            label: "Email:"
        },
        password: {
            label: "Password:",
            type: "password"
        }
    };

    const sundries = (
        <React.Fragment>
            <div>Already have an account? <Link to="/login">Login</Link></div>
        </React.Fragment>
    )

    return (
        <ApolloProvider client={client}>
            <FormWrapper
                ctaText="Create your free account"
                dbQueryName="signup"
                successRoute="/a/onboard"
                submitErrMsg="Try again!"
                inputFields={inputFields}
                schema={schema}
                query={QUERY}
                sundries={sundries}
            />
        </ApolloProvider>
    );
};

export { SignupForm };
// export { QUERY }