import { gql } from '@apollo/client'

const QUERY = gql`
    mutation Signup($email:String!,$password:String!) {
        signup(input: {
            email: $email,
            password: $password,
        })
    }
`

export { QUERY }